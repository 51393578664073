<template>
    <div  style="background-color: #F4F6F9;">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
        <div id="card1" class="card d-flex justify-content-center">
           
            <div class="py-4 px-4 mx-0 lg:mx-12"  >
                <div class="grid justify-content-between">
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                            <h4 style="color: #6b719b; font-size: 20px; font-weight: 700">Edit Ticket</h4>
                        </div>
                    </div>
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
                            <Button class="p-button-primary" size="small"  label="Back"  v-tooltip.bottom="'back'" @click="Goback"/>&nbsp;
                        </div>
                    </div>
                </div>
               
            </div>
            
           
            
            <div class="col-12">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText id="ticket_title" type="text" v-model="title"
                             />
                            <label for="ticket_title">Ticket Title</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText id="email" type="text" v-model="email_id"
                             />
                            <label for="email">Email</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown 
                        id="Category" 
                        v-model="ticket_category_selected" 
                        :options="ticket_category_dropdown" 
                        :value="ticket_category_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="Category">Category</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown 
                        id="type" 
                        v-model="ticket_type_selected" 
                        :options="ticket_type_dropdown" 
                        :value="ticket_type_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="type">Ticket Type</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <MultiSelect 
                        id="type" 
                        v-model="agent_type_selected" 
                        :options="agent_type_dropdown" 
                        :value="agent_type_dropdown"
                        optionLabel="name" 
                        display="chip"
                        filter
                        ></MultiSelect>
                        <label for="type">Agent</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown 
                        id="customer" 
                        v-model="customer_type_selected" 
                        :options="customer_type_dropdown" 
                        :value="customer_type_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="customer">Customer</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown 
                        id="Source" 
                        v-model="source_type_selected" 
                        :options="source_type_dropdown" 
                        :value="source_type_dropdown"
                        optionLabel="name" 
                        filter
                        ></Dropdown>
                        <label for="Source">Source</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <FileUpload
                            
                            class="p-button-primary"
                            mode="basic"
                            name="model[]"
                            
                            :auto="true"
                            :custom-upload="true"
                            choose-label="Attachment"
                            @uploader="handleFileUpload($event)"
                        />
                    </div>
                    <div class="field col-1 md-2" style="margin-top: 1%">
                        <Button
                            class="p-button-danger"
                            mode="basic"
                            @click="cancelFileUpload()" 
                            style="box-shadow: 0 2px 6px rgba(0,176,176,.549); border-color: #ff0000!important; background-color: #ff0000!important;"
                            >
                            Cancel <!-- Button label -->
                        </Button>
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <h4 style="color: #6b719b; font-size: 20px; font-weight: 600">Attachment : {{ attachments_count }} </h4>
                    </div>
                    <div class="field col-12 md:col-12" >
                        
                        <Editor v-model="description" editorStyle="height: 200px"/>
                       
                       
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-12 d-flex text-center justify-content-center">
               <Button class="p-button-primary" @click="submit_ticket()" label="Submit Ticket"/>
            </div>
        </div>
        <ScrollTop />
    </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
export default {
    name: "EticketAdminCreateTicket",
    data() {
        return {
            isLoadingModel:false,
            fullPage:true,

            type:"",
            ticket_type_dropdown:[],
            ticket_type_selected:"",

            category_id:"",
            ticket_category_dropdown:[],
            ticket_category_selected:"",

            customer_id:"",
            customer_type_dropdown:[],
            customer_type_selected:"",

            source_type_dropdown:[],
            source_type_selected:"",

            agent_id:[],
            agent_type_dropdown:[],
            agent_type_selected:"",
            ticket_id:"",
            status:"",
            source_selected:"",

           
            email_id:"",
            title:"",
            attachments:[],
            attachments_count:0,
            description:"",
            client_id:"",

            file_data: 
            {
                filePath: "",
                contentType: "",
            },

        };
    },
    async mounted() 
    { 
        this.client_id=localStorage.getItem('client_id');
        this.client_id='65a4f82f6177e69880ece5d6';
        await this.getTicketTypeMaster();
        await this.getTicketCategoryMaster();
        await this.getCustomerMaster();
        await this.getAgentMaster();
        await this.getTicketSource();
        var local_data=[];
        if (localStorage.getItem("TicketDetails")) 
        {
                local_data = await JSON.parse(localStorage.getItem("TicketDetails"));
                var details = local_data[0];
                console.log(details);
                this.title=details.title;
                this.email_id=details.customer_email;
                this.attachments=details.attachments;
                this.attachments_count=details.attachments.length;
                this.description=details.description;
                this.ticket_id=details.ticket_no;
                this.status=details.status;
                this.ticket_category_selected=details.ticket_category[0];
                this.ticket_type_selected=details.ticket_type[0];
                
                //this.agent_type_selected=details.agent_details;
                var temp_Agent=[];
                for (let index = 0; index < details.agent_details.length; index++) 
                {
                  
                    temp_Agent.push({_id:details.agent_details[index].agent_id,name:details.agent_details[index].agent_name});
                }
                this.agent_type_selected=temp_Agent;

                
                this.customer_type_selected ={_id:details.customer_id    ,   name:details.customer_name};
                this.source_type_selected   ={_id:details.source  ,   name:details.source};
        }       
        
    },
    methods: {
        cancelFileUpload() {
      this.file_data = {
        filePath: "",
        contentType: "",
      };
      this.attachments = [];
      this.attachments_count = 0;
    },
        async Goback()
        {
            this.$router.push({ name: "tickets" });
        },
        async onClick()
        {
            this.$emit("redirect_to_login");
        },
        async submit_ticket()
        {   //pending
            var name_regex = /^\s*$/;
            if (this.title=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Enter Ticket Title",
                life: 3000,
            });
            return false;
            }
                if (!/^[a-zA-Z\s]*$/.test(this.title)) {
                this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "Please enter a valid title name containing only letters.",
                life: 3000
        });
        return false;
}
     
            if (this.title.length>500) {
                this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Enter Valid Tittle Name",
                life: 3000,
                });
                return false;
            }

            if (this.title) 
            {   
                if (name_regex.test(this.title)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        detail: "Please Enter Valid Title",
                        life: 3000,
                    });
                    return false;
                }                
            }
            var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if (this.email_id=="" || this.email_id==undefined) 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Enter Email ID",
                life: 3000,
            });
            return false;
            }

            if (this.email_id) 
            {   
                if (!emailreg.test(this.email_id)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        detail: "Please Enter Valid Email ID",
                        life: 3000,
                    });
                    return false;
                }                
            }


            if (this.ticket_category_selected=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Select Ticket Category",
                life: 3000,
            });
            return false;
            }

            if (this.ticket_type_selected=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error" ,
                detail: "Please Select Ticket Type",
                life: 3000,
            });
            return false;
            }

            if (this.agent_type_selected=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Select Agent",
                life: 3000,
            });
            return false;
            }  
            
            if (this.customer_type_selected=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Select Customer",
                life: 3000,
            });
            return false;
            }

            if (this.source_type_selected=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Select Source",
                life: 3000,
            });
            return false;
            } 
            
            if (this.description=="") 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Enter Description",
                life: 3000,
            });
            return false;
            }  
            //alert(this.description.length);
            if (this.description.length>5000) {
                this.$toast.add({
                severity: "error",
                summary: "error",
                detail: "Please Enter Valid Description",
                life: 3000,
                });
                return false;
            }          

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to submit ticket?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {

                    //get category id
                    this.category_id =this.ticket_category_selected._id;
                    // get ticket type
                    this.type = this.ticket_type_selected._id;
                    // get customer type
                    this.customer_id = this.customer_type_selected._id;

                    this.source_selected=this.source_type_selected._id
                    // get agent type
                    //this.agent_id = this.agent_type_selected._id;
                    for (let index = 0; index < this.agent_type_selected.length; index++) {
                        this.agent_id.push(this.agent_type_selected[index]._id);
                        
                    }

                    var payload=
                    {   
                        ticket_no:this.ticket_id,
                        "user_id":localStorage.getItem("id"),
                        "customer_id":this.customer_id,
                        "title":this.title,
                        "email_id": this.email_id,
                        "category_id":this.category_id,
                        "type":this.type,
                        "attachments":this.attachments,
                        "description":this.description,
                        "client_id":localStorage.getItem("client_id"),
                        "agent_id":this.agent_id,
                        "status":this.status,
                        "source":this.source_selected

                    }
                    this.isLoadingModel = true;
                    var promise = apis.EditTicket(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status==true) 
                        {
                            this.$swal(response.data.message);
                            this.reset_form();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            this.$router.push({ name: "tickets" });
                            
                        }
                        else
                        {
                            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', 
                    summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                    this.reset_form();
                }
            });
        },
        async getTicketSource()
        {   
            let data={
                "client_id":"65a4f82f6177e69880ece5d6",
                "status":"Active",
                "count":false,
                "limit":100000,
                "page_no":1
            }
            this.isLoadingModel = true;
            var promise = apis.getTicketSource(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                var local_temp=response.data.data;
                var local_array=[];
                for (let index = 0; index < local_temp.length; index++) 
                {
                    local_array.push({_id:local_temp[index].name,name:local_temp[index].name})
                }
                this.source_type_dropdown = local_array;
            });
        },
        async reset_form()
        {
            this.file_data= 
            {
                filePath: "",
                contentType: "",
            }
   
            this.email_id="";
          
            this.title="";
            this.category_id="";
            this.type="";
            this.attachments=[];
            this.attachments_count=0;
            this.description="";
            this.ticket_category_selected="";
            this.ticket_type_selected="";
            this.agent_type_selected="";
            this.customer_type_selected="";
            this.source_type_selected="";
        },
        async handleFileUpload(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                  this.attachments.push("https://eticket-docs.s3.ap-south-1.amazonaws.com/" +
                    filename);
                  this.attachments_count = this.attachments_count + 1;
                   
                });
            });

        },
        async date_time_prefix() 
        {
            // Get the current date and time
            const currentDateTime = new Date();

            // Format the date as YYYY_MM_DD
            const formattedDate = currentDateTime.toISOString().split('T')[0].replace(/-/g, '_');

            // Format the time as HH_mm_ss
            const formattedTime = currentDateTime.toTimeString().split(' ')[0].replace(/:/g, '_');

            // Construct the final string
            const resultString = `${formattedDate}_${formattedTime}`;

            return resultString;
        },
        async getTicketTypeMaster()
        {
            var data = {
                "client_id":"65a4f82f6177e69880ece5d6"
            };
            this.isLoadingModel = true;
            var promise = apis.TicketTypeMaster(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                this.ticket_type_dropdown = response.data.data;
            });
        },
        async getTicketCategoryMaster()
        {
            var data = {
                "client_id":"65a4f82f6177e69880ece5d6"
            };
            this.isLoadingModel = true;
            var promise = apis.TicketCategoryMaster(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                this.ticket_category_dropdown = response.data.data;
            });
        },
        async getCustomerMaster()
        {
            var data = {
                user_type:"Customer"
            };
            this.isLoadingModel = true;
            var promise = apis.userlist(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                var local_temp=response.data.data;
                var local_array=[];
                for (let index = 0; index < local_temp.length; index++) 
                {
                    local_array.push({_id:local_temp[index]._id,name:local_temp[index].name})
                }
                this.customer_type_dropdown = local_array;
            });
        },
        async getAgentMaster()
        {
            var data = {
                user_type:"Agent"
            };
            this.isLoadingModel = true;
            var promise = apis.userlist(data);
            promise.then((response) => {
                this.isLoadingModel = false;
                var local_temp=response.data.data;
                var local_array=[];
                for (let index = 0; index < local_temp.length; index++) 
                {
                    local_array.push({_id:local_temp[index]._id,name:local_temp[index].name})
                }
                this.agent_type_dropdown = local_array;
                
            });
        }
    },
};
</script>
<style scoped>
#card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
}

.link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none; 
  }
  .link-style:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  @media screen and (min-width: 992px)
{

}
</style>